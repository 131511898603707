.checkoutSection {
  border-top: 1px solid rgb(179, 179, 179);
  /* padding: 30px 0; */
  margin: 0 20px;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
}

.checkoutDeliverySection {
  width: 550px;
  padding: 20px;
}

.deliveryDetails h4 {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: none;
}

.deliveryDetails input {
  width: 100%;
  padding: 13px;
  border: 1px solid rgb(189, 189, 189);
  margin: 10px 0;
  border-radius: 5px;
}

.deliveryDetails input::placeholder {
  color: rgb(86, 86, 86);
  font-size: 13px;
}

.checkout-delivery-section-name-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkoutDeliverySectionLandmarkInput {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkoutDeliverySectionSubmitInput input {
  background-color: #1c5560;
  cursor: pointer;
  color: white;
  font-weight: bold;
  width: 100%;
  height: 40px;
  border: 1px solid rgb(189, 189, 189);
  margin: 10px 0;
  border-radius: 5px;
}

.purchaseButtonLoader {
  background-color: #1c5560;
  cursor: pointer;
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 8px 13px;
  border: 1px solid rgb(189, 189, 189);
  margin: 10px 0;
  border-radius: 5px;
}

.purchaseButtonLoader img {
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.services {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.services p {
  font-size: 13px;
  color: #1c5560;
  text-decoration: underline;
  cursor: pointer;
}

.checkoutCartSection {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid rgb(255, 238, 238);
}

.checkoutCartSection table {
  border-collapse: collapse;
  width: 100%;
}

.checkoutCartSection table tbody tr td {
  text-align: right;
  font-size: 14px;
  padding: 10px 0;
}

.checkoutCartImagesDetail {
  display: flex;
  gap: 8px;
  width: 100%;
  position: relative;
}

.checkoutCartImagesDetail img {
  width: 50px;
  height: 70px;
  border: 1px solid rgb(195, 195, 195);
}

.checkoutCartImagesDetail span {
  position: absolute;
  top: -10px;
  left: 40px;
  border-radius: 50%;
  background-color: rgb(90, 90, 90);
  color: white;
  padding: 3px 6px;
  font-size: 10px;
}

.checkoutCartDetail {
  line-height: 1.5;
  text-align: start;
}

.checkoutCartDetail p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
}

.checkoutCartDetail p:nth-child(2),
.checkoutCartDetail p:nth-child(3) {
  font-size: 12px;
  color: #363636;
}

.checkoutCartSectionPaymentDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.checkoutCartSectionPaymentDetail div p {
  font-size: 14px;
  color: rgb(35, 35, 35);
  margin: 14px 0;
}

.payTotal {
  color: black;
  font-weight: bold;
  font-size: 16px !important;
}

.billingtop {
  margin: 15px 0;
}

.billingtop h4 {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: none;
}

.billingAddressSection {
  /* max-width: 500px; */
  /* border: 2px solid red; */
}

.billingOptionLabel {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-radius: 2px;
  border: 1px solid #e3e3e3;
  background-color: #fbfbfb;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}

.billingOptionLabel input[type="radio"] {
  margin-right: 10px;
  accent-color: red;
}

.billingOption input[type="radio"]:checked + .billingOptionLabel {
  border-color: red;
  background-color: #fde9e9;
}

.billingOptionLabel:hover {
  border-color: #ccc;
}

.cartAmount {
  padding: 20px 0;
}

.highlight h3 {
  color: #5bb493 !important;
}

.cartAmountRupee {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cartAmountRupee h3 {
  text-align: center;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  text-transform: none;
}

@media screen and (max-width: 1250px) {
  .checkoutSection {
    margin: 0 0px;
  }

  .checkoutDeliverySection {
    width: 450px;
  }

  .checkoutCartSection {
    max-width: 500px;
  }
}

@media screen and (max-width: 1070px) {
  .checkoutDeliverySection {
    width: 400px;
  }

  .checkoutCartSection {
    max-width: 460px;
  }
}

@media screen and (max-width: 903px) {
  .checkoutDeliverySection {
    width: 760px;
  }

  .checkoutCartSection {
    max-width: 760px;
  }

  .checkoutDeliverySection h4 {
    font-size: 15px;
  }
}
