.fullproductContainer {
  text-align: center;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* navigations  */
/* FOR TOP NAVIGATION  */
.productDetailsTopContainerNavigation {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 60px;
  background-color: #f6f6f6;
}

.productDetailsTopContainerNavigation div {
  display: flex;
  align-items: center;
  color: rgb(58, 58, 58);
  justify-content: center;
  font-size: 14px;
}

.productDetailsTopContainerNavigation div a {
  color: rgb(58, 58, 58);
}

.fullproductListContainer {
  text-align: center;
  padding: 50px 0;
}

.fullproductList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* filter secton and heading  */

.fullproductHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;
}

.fullproductHeading h2 {
  margin: 20px 0 30px 0;
  font-weight: bold;
  font-size: 27px;
  text-transform: uppercase;
}

.fullproductHeading .fullproductHeadingFilterIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  position: relative;
}
.filterButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 5.5px 10px;
  background-color: inherit;
  outline: none;
  cursor: pointer;
  color: rgb(30, 27, 27);
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid #2c2c2c;
}
.filterButton img {
  width: 15px;
}
.filterButton:hover {
  background-color: #f4f4f4;
}
.fullproductHeading .fullproductHeadingFilterIcons select {
  padding: 8px 10px;
  background-color: inherit;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  color: black;
  font-size: 14px;
}
.fullproductHeading .fullproductHeadingFilterIcons select:hover {
  background-color: #f4f4f4;
}

.fullProductListPages {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.pageLink {
  border-radius: 4px;
  border: 2px solid #1c5461;
  color: #1c5461;
  padding: 5px 14px;
}

.pageLink.activePage {
  color: white;
  background-color: #1c5461;
}

.PrevNextButton a {
  color: #1c5461;
}

/* skeleton loading .....  */
.skeletonLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
}
.productSkeletonLoading {
  max-width: 250px;
  width: 100%;
  background-color: #ffffff;
  border-width: 1px;
  border-color: #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  margin: 0 10px;
  padding: 1rem;
  border: 1px solid rgb(250, 250, 250);
}

.skeletonLoadingButton {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fullProductNotFound {
  padding-bottom: 50px;
}
.fullProductNotFound h3 {
  font-size: 25px;
}
.fullProductNotFound img {
  width: 27vw;
}
.fullProductNotFound p {
  font-size: 15px;
  color: rgb(99, 99, 99);
  text-transform: none;
}
.fullProductNotFound button {
  font-size: 14px;
  margin: 10px 0;
  padding: 9px 20px;
  background-color: #1c5560;
  color: white;
  border-radius: 17px;
  border: none;
  text-transform: none;
}

/* skeleton loading .....  */
@media screen and (max-width: 560px) {
  .productSkeletonLoading {
    max-width: 220px;
    margin: 0 5px;
    padding: 10px;
  }
}

@media screen and (max-width: 475px) {
  .productSkeletonLoading {
    max-width: 180px;
    margin: 0 5px;
    padding: 10px;
  }
}

@media screen and (max-width: 800px) {
  .productDetailsTopContainerNavigation {
    padding-left: 35px;
    background-color: #f6f6f6;
  }
  .fullProductNotFound {
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .fullProductNotFound h3 {
    font-size: 20px;
  }
  .fullProductNotFound img {
    width: 50vw;
  }
  .fullProductNotFound p {
    font-size: 13px;
  }
  .fullProductNotFound button {
    font-size: 12px;
    margin: 10px 0;
    padding: 9px 20px;
  }
}

@media screen and (max-width: 720px) {
  .fullproductContainer {
    padding: 15px 0;
    line-height: 1.6;
  }
  .fullProductNotFound {
    padding-bottom: 20px;
    padding-top: 0px;
  }
  .fullProductNotFound img {
    width: 65vw;
  }
}

@media screen and (max-width: 630px) {
  .fullproductHeading {
    flex-direction: column;
    justify-content: center;
    padding: 0px;
  }

  .fullproductHeading h2 {
    margin: 20px 0 20px 0;
    font-size: 22px;
  }
}
