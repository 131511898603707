.accountInfo {
  margin: auto;
  padding: 20px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  max-width: 500px;
}
.accountInfoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.accountInfoHeader img {
  width: 16px;
}
.accountInfoHeader div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid rgb(191, 191, 191);
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
}
.accountInfoHeader div:hover {
  background-color: rgb(218, 218, 218);
}
.accountInfoHeader div p {
  font-size: 14px;
}
.accountInfo ul {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
}

.accountInfo ul li {
  padding: 15px 20px;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.accountInfo ul li:last-child {
  border-bottom: none;
}

.accountInfo ul li b {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.accountInfo ul button {
  padding: 10px 20px;
  background-color: #1c5560;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
  width: 100%;
}

.accountInfo ul button:hover {
  background-color: #3b8998;
}

.profileSkeleton {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the entire skeleton horizontally */
  justify-content: center;
  gap: 1.3rem;
  padding: 5rem 0;
}

/* Editable Input Fields */
.accountInfo input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.accountInfo input::placeholder {
  font-size: 14px;
  color: #868686;
}
/* Edit Buttons */
.editActions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
}
.saveButtonDisabled {
  background-color: #3b8998 !important;
  cursor: not-allowed !important;
}
.saveButton img {
  width: 20px;
}
/* Cancel & Save Buttons */
.editActions button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.2s;
}

.cancelButton {
  color: black !important;
  border: 1px solid #ccc !important;
  background-color: white !important;
}

.cancelButton:hover {
  background-color: #dcdcdc !important;
}

@media screen and (max-width: 600px) {
  .accountInfo {
    padding: 15px;
    max-width: 90%;
  }

  .accountInfo ul li {
    padding: 10px;
    font-size: 16px;
  }

  .accountInfo ul button {
    padding: 8px 15px;
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .accountInfo ul li {
    padding: 8px;
    font-size: 14px;
  }

  .accountInfo ul button {
    padding: 8px;
    font-size: 14px;
  }

  hr {
    margin: 5px 0;
  }
  .profileSkeleton {
    gap: 0.7rem;
    padding: 3rem 4rem;
  }
}
