.fullproductContainer {
  text-align: center;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* navigations  */
/* FOR TOP NAVIGATION  */
.productDetailsTopContainerNavigation {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 60px;
  background-color: #f6f6f6;
}

.productDetailsTopContainerNavigation div {
  display: flex;
  align-items: center;
  color: rgb(58, 58, 58);
  justify-content: center;
  font-size: 14px;
}

.productDetailsTopContainerNavigation div a {
  color: rgb(58, 58, 58);
}

.fullproductListContainer {
  text-align: center;
}

.fullproductList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
}

/* filter secton and heading  */

.fullproductHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  margin-top: 20px;
  /* border: 2px solid red; */
}

.fullproductHeading h2 {
  font-weight: 600;
  font-size: 30px;
}

.fullproductHeading .fullproductHeadingFilterIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  position: relative;
}

.filterButton {
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 5.5px 10px;
  background-color: inherit;
  outline: none;
  cursor: pointer;
  color: rgb(30, 27, 27);
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #c8c8c8;
  align-items: center;
}
.filterButton img {
  width: 15px;
}
.filterButton:hover {
  background-color: #f4f4f4;
}
.fullproductHeading .fullproductHeadingFilterIcons select {
  padding: 8px 10px;
  background-color: inherit;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  color: black;
  font-size: 14px;
  border: 1px solid #c8c8c8;
}
.fullproductHeading .fullproductHeadingFilterIcons select:hover {
  background-color: #f4f4f4;
}
/* button filter about and cross filter   */
.totalProduct {
  color: #71717a;
  font-size: 15px;
  text-align: start;
  padding: 0 60px;
}
.selectedFilter {
  color: #000000;
  font-size: 15px;
  text-align: start;
  padding: 0 60px;
  font-weight: 500;
  margin: 20px 0 10px 0;
}

/* full page  */
.fullProductFilterContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* justify-content: space-between; */
  padding: 50px 60px;
}
.sidebar {
  width: 350px;
  min-width: 350px;
  background-color: #fff;
  text-align: start;
}

.filterSection {
  margin-bottom: 20px;
}

.filterSection h3 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 8px;
}

.filterSection label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
  margin: 4px 0;
}
/* Style for checkboxes */
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: black; /* Changes the checkmark color to black */
  cursor: pointer;
}

.slider {
  width: 100%;
  -webkit-appearance: none;
  height: 8px;
  background: black;
  outline: none;
  border-radius: 20px;
  opacity: 1;
  transition: opacity 0.2s;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid black;
}

.fullProductListPages {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.pageLink {
  border-radius: 4px;
  border: 2px solid #1c5461;
  color: #1c5461;
  padding: 5px 14px;
}

.pageLink.activePage {
  color: white;
  background-color: #1c5461;
}

.PrevNextButton a {
  color: #1c5461;
}

/* skeleton loading .....  */
.productSkeletonLoading {
  max-width: 250px;
  width: 100%;
  background-color: #ffffff;
  border-width: 1px;
  border-color: #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  margin: 0 10px;
  padding: 1rem;
  border: 1px solid rgb(250, 250, 250);
}

.skeletonLoadingButton {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fullProductNotFound p {
  font-weight: bold;
}
@media screen and (max-width: 1115px) {
  .fullProductFilterContainer {
    padding: 20px 0;
  }
  .sidebar {
    display: none;
  }
}
/* skeleton loading .....  */
@media screen and (max-width: 560px) {
  .productSkeletonLoading {
    max-width: 220px;
    margin: 0 5px;
    padding: 10px;
  }
}

@media screen and (max-width: 475px) {
  .productSkeletonLoading {
    max-width: 180px;
    margin: 0 5px;
    padding: 10px;
  }
}

@media screen and (max-width: 800px) {
  .productDetailsTopContainerNavigation {
    padding-left: 35px;
    background-color: #f6f6f6;
  }
}

@media screen and (max-width: 720px) {
  .fullproductContainer {
    padding: 15px 0;
    line-height: 1.6;
  }
}

@media screen and (max-width: 630px) {
  .fullproductHeading {
    flex-direction: column;
    justify-content: center;
    padding: 0px;
  }

  .fullproductHeading h2 {
    margin: 20px 0 20px 0;
    font-size: 22px;
  }
}
