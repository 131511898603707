.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Ensure it is below the sidebar */
  display: none;
}
.overlay.open {
  display: block;
}
.searchSidebar {
  position: fixed;
  top: 0;
  right: -350px;
  width: 350px;
  height: 100%;
  background-color: white;
  transition: right 0.3s ease;
  z-index: 1001;
  padding: 15px;
}

.searchSidebar.open {
  right: 0;
}
/* top header text and close button  */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header h2 {
  color: #222222;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.closeButton img {
  width: 20px;
}
/* about text  */
.aboutText {
  color: #71717a;
  font-size: 14px;
  margin: 10px 0 15px 0;
}
.sidebar {
  background-color: #fff;
  text-align: start;
}

.filterSection {
  border-bottom: 1px solid rgb(215, 215, 215);
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.filterSection h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}
.dropdownList {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdownList img {
  width: 20px;
}
.dropdownContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.dropdownList img {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.applyButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
}
.applyButton button {
  background-color: #1b1b1b;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid black;
  margin-top: 20px;
}
.applyButton button:nth-child(1) {
  background-color: #fff;
  color: #000;
  border: 1px solid rgb(199, 199, 199);
}
.filterSection label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
  margin: 4px 0;
}
/* Style for checkboxes */
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: black; /* Changes the checkmark color to black */
  cursor: pointer;
}

.slider {
  width: 100%;
  -webkit-appearance: none;
  height: 8px;
  background: black;
  outline: none;
  border-radius: 20px;
  opacity: 1;
  transition: opacity 0.2s;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid black;
}
