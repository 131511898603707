.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  margin: 0 10px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  position: relative;
}
.closeButton {
  position: absolute;
  top: 8px;
  right: 14px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.modalContent h2 {
  font-size: 16px;
  text-align: left;
  text-transform: none;
}
.modalContent h3 {
  font-size: 18px;
  text-align: center;
  text-transform: none;
}
.modalContent p {
  font-size: 14px;
  color: #666;
  margin: 12px 0 15px 0;
  text-align: center;
  text-transform: none;
}
.modalIcon {
  font-size: 40px;
  color: #666;
  margin: 10px 0;
}

/* .closeButton {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 4px;
}

.closeButton:hover {
  background: #0056b3;
} */
