/* .myordersPage {} */

.myordersCategory {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 10px;
  gap: 20px;
}

/* navigations  */
/* FOR TOP NAVIGATION  */
.productDetailsTopContainerNavigation {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 60px;
  background-color: #f6f6f6;
}

.productDetailsTopContainerNavigation div {
  display: flex;
  align-items: center;
  color: rgb(58, 58, 58);
  justify-content: center;
  font-size: 14px;
}

.productDetailsTopContainerNavigation div a {
  color: rgb(58, 58, 58);
}

.myordersCategory div button {
  padding: 9px 15px;
  border-radius: 26px;
  border: 1px solid rgb(152, 152, 152);
  outline: none;
  background-color: white;
  font-size: 14px;
  text-transform: none;
  transition: 0.2s;
}

.myordersCategory div button:hover {
  background-color: rgb(247, 247, 247);
  transition: 0.2s;
}

.statusBtnActive {
  background-color: #1c5560 !important;
  color: white;
}

.myordersList {
  margin: 30px 0;
  padding: 0 20px;
}

.myordersContainer {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 10px;
  max-width: 1091px;
  margin: 30px auto;
  cursor: pointer;
  padding: 0 48px;
}

.myordersCotainer1 {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myordersCotainer1 div:nth-child(1) p:nth-child(1) {
  font-weight: bold;
  font-size: 18px;
}

.myordersCotainer1 div:nth-child(1) p:nth-child(2) {
  font-size: 15px;
  color: #636363;
  margin-top: 3px;
}

.myordersCotainer1 div:nth-child(2) {
  font-size: 18px;
  font-weight: bold;
}

/* product container (orderitems) */
.myordersCotainer2 {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  align-items: center;
}

/* left container of img and product details  */
.myordersContainer2Left {
  display: flex;
}

/* img  */
.myordersContainer2Left div:nth-child(1) img {
  width: 60px;
  height: 80px;
}

/* product detail container  */
.myordersContainer2Left div:nth-child(2) {
  margin-left: 13px;
}

/* product name and status container  */
.myordersContainer2Left div:nth-child(2) div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* product name  */
.myordersContainer2Left div:nth-child(2) div:nth-child(1) div:nth-child(1) {
  font-weight: bold;
  font-size: 17px;
}

/* status  */
.myorderStatus {
  color: #ea580c;
  margin-left: 10px;
  background-color: #ffedd5;
  padding: 4px 10px;
  font-size: 11px;
  border-radius: 40px;
  font-weight: 600;
}

.myorderStatusCancel {
  color: rgb(255, 57, 57);
  background-color: #ffd3d3;
}

.myorderStatusDelivered {
  color: #16a34a;
  background-color: #dcfce7;
}

/* dress and item no.  */
.myordersContainer2Left div:nth-child(2) p:nth-child(2) {
  font-size: 15px;
  margin: 5px 0;
  color: #757575;
}

/* price  */
.myordersContainer2Left div:nth-child(2) p:nth-child(3) {
  font-size: 15px;
  font-weight: bold;
}

/* right icon  */
.myordersContainer2Right i {
  font-size: 25px;
  color: #7c7c7c;
}

.ordernotfound {
  display: flex;
  padding: 10px 0 20px 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ordernotfound img {
  width: 300px;
}

.ordernotfound p {
  font-size: 20px;
  font-weight: bold;
}

.ordernotfound button {
  font-size: 12px;
  margin: 10px 0;
  padding: 7px 14px;
  border: 2px solid #1c5560;
  color: #1c5560;
  background-color: white;
  border-radius: 17px;
  text-transform: none;
  transition: 0.2s;
}

.ordernotfound button:hover {
  background-color: #1c5560;
  color: white;
  transition: 0.2s;
}
.cartLoadingMainSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  margin: 30px 0;
  padding: 20px 70px;
}

.cartLoadingSection {
  display: flex;
  gap: 15px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
}
.cartLoadingSection * {
  margin: 5px 0;
}

@media screen and (max-width: 800px) {
  .productDetailsTopContainerNavigation {
    padding-left: 35px;
    background-color: #f6f6f6;
  }
}

@media screen and (max-width: 700px) {
  .myordersContainer {
    padding: 0 20px;
  }

  .myordersCotainer1 div:nth-child(1) p:nth-child(1) {
    font-size: 15px;
  }

  .myordersCotainer1 div:nth-child(1) p:nth-child(2) {
    font-size: 12px;
  }

  .myordersCotainer1 div:nth-child(2) {
    font-size: 14px;
  }

  .myordersContainer2Left div:nth-child(1) img {
    width: 50px;
    height: 70px;
  }

  .myordersContainer2Left div:nth-child(2) div:nth-child(1) div:nth-child(1) {
    font-size: 15px;
  }

  .myorderStatus {
    padding: 4px 8px;
    font-size: 10px;
  }

  .myordersContainer2Left div:nth-child(2) p:nth-child(2) {
    font-size: 12px;
  }

  .myordersContainer2Left div:nth-child(2) p:nth-child(3) {
    font-size: 13px;
  }

  .ordernotfound p {
    font-size: 15px;
  }

  .ordernotfound button {
    font-size: 10px;
    margin: 10px 0;
    padding: 7px 13px;
  }
}
@media screen and (max-width: 600px) {
  .cartLoadingMainSection {
    gap: 2rem;
    padding: 20px 30px;
  }
}
@media screen and (max-width: 450px) {
  .myordersCategory {
    margin: 30px 20px;
    gap: 15px;
  }

  .myordersCategory div button {
    padding: 7px 10px;
    border-radius: 30px;
    font-size: 10px;
  }
}
