.reviewsContainer {
  max-width: 1300px;
  margin: 0 auto;
  padding: 100px 20px;
  text-align: center;
}

.slider {
  padding: 20px 0; /* Remove vertical margin to avoid extra space */
}

.reviewsTitle {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 7px;
}
.reviewTagline {
  font-size: 16px;
  text-align: center;
  color: #808080;
  margin-bottom: 40px;
}
.reviewCard {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  margin: 0; /* Adjust this value to control gap between cards */
  max-width: 400px;
  height: 200px;
}

.reviewStars {
  margin-bottom: 10px;
}

.star {
  color: #ccc;
  font-size: 20px;
}

.star.filled {
  color: #fbc02d;
}

.reviewText {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
  line-height: 1.5;
}

.reviewAuthor {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 10px;
}
@media screen and (max-width: 700px) {
  .reviewTagline {
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
  .reviewsTitle {
    font-size: 1.7rem;
  }
}
