.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Ensure it is below the sidebar */
  display: none;
}
.overlay.open {
  display: block;
}
.searchSidebar {
  position: fixed;
  top: 0;
  right: -350px;
  width: 350px;
  height: 100%;
  background-color: white;
  transition: right 0.3s ease;
  z-index: 1001;
}

.searchSidebar.open {
  right: 0;
}
.header {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
}
.header h2 {
  color: #222222;
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.closeButton img {
  width: 20px;
}

.content {
  padding: 15px 20px;
  border-bottom: 1px solid #dfdfdf;
  position: relative;
}
.content img {
  width: 20px;
  position: absolute;
  right: 35px;
  top: 25px;
  cursor: pointer;
}
.dropdown {
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  background-color: white;
  outline: none;
  font-size: 13px;
  line-height: 18px;
}

.searchInput {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #ccc;
  border-radius: 30px;

  font-size: 13px;
  line-height: 18px;
  outline: none;
  font-weight: 400;
  color: #222222;
}
.searchInput::placeholder {
  font-size: 13px;
  color: #222222;
}

.searchContent {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: calc(100% - 160px);
  overflow-y: auto;
  padding-bottom: 130px;
}
.searchContent p:nth-child(1) {
  font-weight: 600;
  font-size: 14px;
}
.searchItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  overflow-y: auto;
  max-height: 100%; /* Ensure it takes the full height of the parent */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.searchProduct {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
}
.productImg {
  width: 90px;
  height: 100px;
}
.productInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.productInfoPrices {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
}
.productPrice {
  font-size: 15px;
  font-weight: 500;
}
.originalPrice {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: line-through;
  color: #767676;
}
.searchProduct div h3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
/* rating  */
.rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.rating div p {
  color: #71717a;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.ratingStar {
  display: flex;
  align-items: center;
}
.ratingStar img {
  width: 17px;
}
.rating p {
  color: #555555;
  font-size: 13px;
}
.noProductFound {
  font-size: 14px;
  font-weight: 500;
  color: #878787;
  margin-top: 20px;
}
