.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 450px;
  margin: 0 15px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 18px;
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.orderId {
  color: black;
}

.subtitle {
  color: gray;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 4px 0 23px 0;
}

.warning {
  color: #ef4441;
  border: 1px solid #ef4441;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  gap: 7px;
  margin-bottom: 10px;
}
.warning div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.warning div h5 {
  letter-spacing: -0.025em;
  line-height: 1;
  font-weight: 500;
  font-size: 15px;
}
.warning div p {
  font-size: 0.8rem;
  line-height: 1.25rem;
}

.warning img {
  width: 1rem;
}
.formGroup {
  margin-bottom: 10px;
  margin-top: 20px;
}
.formGroup label {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.select,
.textarea {
  width: 100%;
  background-color: white;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.textarea {
  resize: none;
  height: 100px;
}

.textarea::placeholder {
  font-size: 13px;
  color: gray;
}
.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.keepOrder {
  background: #fff;
  border: 1px solid #bcbcbc;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.cancelOrder {
  background: #d9534f;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}
.cancelOrder img {
  width: 18px;
}
.cancelOrderDisable {
  color: white;
  background-color: #e9726e;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: not-allowed;
}
.cancelOrder:hover {
  background: #e9726e;
}
