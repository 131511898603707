.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  margin: 0 15px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 600px) {
  .modal {
    width: 400px;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 18px;
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.orderId {
  color: black;
}

.subtitle {
  color: gray;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 4px 0 23px 0;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  /* gap: 10px; */
}
.tabs button {
  background-color: white;
  border: none;
  outline: none;
  padding: 3px;
  border-bottom: 1px solid rgb(170, 170, 170);
  font-size: 16px;
  width: 50%;
  cursor: pointer;
  color: rgb(170, 170, 170);
}
.tabs button.active {
  border-bottom: 1px solid gray;
  color: black;
}
.emailSection {
  border: 1px solid rgb(187, 187, 187);
  padding: 15px;
  border-radius: 5px;
}
.emailSection div h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 17px;
  display: flex;
  gap: 5px;
}

.emailSection div p {
  color: #4f4f4f;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 4px 0 23px 0;
}
.emailSection div h6 {
  color: #313131;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  gap: 5px;
}
@media screen and (max-width: 600px) {
  .emailSection div h3 {
    font-size: 15px;
  }
  .emailSection div h6 {
    font-size: 0.8rem;
  }
}
