.loginform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.loginform h3 {
  margin-bottom: 45px;
}

.lform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.password-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  /* font-size: 40px; */
}





.lform input {
  width: 270px;
  height: 33px;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(174, 171, 171);
  margin-top: 20px;
}

.fogpass {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: black;
  margin-top: 10px;
}

.fogpass:hover {
  color: rgb(88, 88, 88);
}

.lform span {
  font-size: 12px;
  margin-top: 9px;
}

.formspana {
  color: black;
}

.formspana:hover {
  color: rgb(88, 88, 88);
}

.formbtn {
  cursor: pointer;
  background-color: black;
  width: 270px;
  height: 33px;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(174, 171, 171);
  margin-top: 20px;
  color: white;
}

.formbtn.loading {
  cursor: not-allowed;
  background-color: rgb(88, 88, 88);
}

.formbtn:hover {
  background-color: rgb(88, 88, 88);
}

::placeholder {
  color: rgb(0, 0, 0);
  font-size: 11px;
  font-family: "Noto Sans", sans-serif;
}

.loginforminput {
  padding: 0 13px;
}




.social-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.social-login-divider {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  position: relative;
}

.social-login-divider::before,
.social-login-divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 43%;
  height: 1px;
  background-color: #ccc;
}

.social-login-divider::before {
  left: 0;
}

.social-login-divider::after {
  right: 0;
}

.social-login-divider span {
  background-color: #fff;
  padding: 0 10px;
}

.social-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 37px;
  border-radius: 5px;
  text-transform: none;
  margin: 5px 0;
  border: none;
  cursor: pointer;
  font-size: 17px;
}

.social-icon {
  margin-right: 10px;
}


.google-login {
  background-color: #fff;
  color: #757575;
  border: 1px solid #ddd;
}

.google-login:hover {
  background-color: #f7f7f7;
}