* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-style: sans-serif;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-tap-highlight-color: transparent;
  transition: outline 0.3s ease;
  /* user-select: none; 
  -webkit-user-select: none; 
  -ms-user-select: none; */
}
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.noScroll {
  overflow: hidden;
}

.loaderbutton {
  .loader {
    width: 40px;
  }
}

button {
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  outline: none;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
  font-family: "Poppins", serif;
}

.screenLoader {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.screenLoader img {
  width: 55px;
  height: 70px;
}

.screenLoader span {
  color: #1d555d;
  font-size: 17px;
  margin-left: 8px;
}
