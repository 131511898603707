.whatsappButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #22ce5b;
  color: white;
  width: 45px;
  height: 45px;
  border-radius: 50px; /* Rounded corners for smooth shape change */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  transition: all 0.3s ease-in-out; /* Smooth transitions for all properties */
  overflow: hidden; /* Prevent content overflow during animation */
}

.whatsappButton i {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 8px; */
}

.whatsappButton p {
  font-size: 15px;
  margin: 0;
  opacity: 0;

  white-space: nowrap; /* Prevent text wrapping */
  transition: opacity 0.3s ease-in-out;
}

.whatsappButton:hover {
  background-color: #0eb144;
  width: 130px; /* Expanded width when hovered */
  border-radius: 25px; /* Oval shape during hover */
}

.whatsappButton:hover p {
  opacity: 1; /* Text becomes visible on hover */
  margin-left: 5px;
}
