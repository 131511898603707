.footer {
  background-color: #f2f2f2;
  margin-top: 30px;
}

.footer a {
  transition: all 0.3s ease;
}

.footerContent {
  margin: auto;
  padding: 50px 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer .footer-content .footerSection {
  transition: all 0.4s ease;
}

/* common for all heading  */
.footerSection div h2 {
  font-size: 20px;
  font-weight: 600;
  color: #1d1d1d;
  margin-bottom: 16px;
  text-transform: none;
}

.footerSection div img {
  width: 150px;
  margin-bottom: 20px;
}

.footerLeftSection {
  width: 28%;
}

.footerLeftSection div p {
  color: #1d1d1d;
  line-height: 1.45;
}

/* middle section  */

.footerMiddleSection {
  padding: 0 25px;
}

.footerMiddleSection a {
  line-height: 32px;
  color: #1d1d1d;
}

.footerMiddleSection a:hover {
  text-decoration: underline;
}

/* right section  */
.footerRightSection {
  padding: 0 25px;
}

.footerRightSection div:nth-child(2),
.footerRightSection div:nth-child(3),
.footerRightSection div:nth-child(4) {
  display: flex;
  line-height: 2.4;
}

.footerRightSection div i {
  display: flex;
  font-size: 20px;
  color: rgb(85, 85, 85);
  align-items: center;
}

.footerRightSection div span {
  margin-left: 10px;
}

.footerRightSection-media-icons {
  display: flex;
}

.footerRightSection-media-icons a {
  font-size: 18px;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  line-height: 43px;
  margin: 10px 0px 0 0;
  transition: all 0.3s ease;
}

.footerRightSection-media-icons a i:hover {
  font-size: 28px;
  transition: 0.2s;
  color: rgb(0, 0, 0);
}

@media (max-width: 1076px) {
  .footer .footer-content {
    justify-content: space-between;
  }

  .footerLeftSection {
    width: 50%;
  }

  .footerMiddle2Section {
    /* margin-top: 40px; */
  }

  .footerMiddleSection {
    /* margin-top: 40px; */
  }

  .footerRightSection {
    margin-top: 40px;
  }
}

@media (max-width: 788px) {
  .footerMiddleSection {
    padding: 0;
  }

  .footerRightSection {
    margin-top: 40px;
    padding: 0;
  }
}

@media (max-width: 560px) {
  .footerLeftSection {
    width: 100%;
  }

  .footerMiddleSection {
    margin-top: 40px;
  }
}

.footerCopyRight p {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  color: black;
}

@media screen and (max-width: 600px) {
  .footerCopyRight p {
    font-size: 12px;
  }
  .footerLeftSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
