.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  margin: 0 15px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 18px;
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

.orderId {
  color: black;
}

.subtitle {
  color: gray;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 4px 0 23px 0;
}

.formGroup {
  margin-bottom: 10px;
  margin-top: 20px;
}
.rating {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.rating img {
  width: 28px;
  cursor: pointer;
}
.formGroup label {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.select,
.textarea {
  width: 100%;
  background-color: white;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.textarea {
  resize: none;
  height: 100px;
}

.textarea::placeholder {
  font-size: 13px;
  color: gray;
}
.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.buttonGroup button {
  background: #151212;
  color: white;
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}
.buttonGroup button img{
  width: 20px;
}
.disabledButton button{
  background: gray !important;
  cursor: not-allowed !important;
}

.buttonGroup button:hover {
  background: #2b2b2b;
}
