.serviceMain {
  background-color: #1c1c1c;
}
.serviceMain h1 {
  color: white;
  padding-top: 40px;
  text-align: center;
  text-transform: none;
}
.serviceSectionHome {
  padding: 50px 0 70px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  /* border: 2px solid red; */
}

.serviceSections div i {
  color: #ffffff;
  font-size: 50px;
}

.serviceSections div h3 {
  margin-bottom: 5px;
  letter-spacing: 0.05em;
  text-transform: none;
  color: white;
  font-size: 18px;
}

.serviceSections div p {
  margin: 0 0 15px 0;
  font-size: 15px;
  color: rgb(131, 131, 131);
}

@media screen and (max-width: 650px) {
  .serviceSections div i {
    font-size: 40px;
  }

  .serviceSections div h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .serviceSectionHome {
    flex-direction: column;
  }

  .serviceSections {
    margin: 15px 0;
  }

  .serviceSections div i {
    font-size: 40px;
  }
}

@media screen and (max-width: 450px) {
  .serviceSections {
    margin: 10px 0;
  }

  .serviceSectionHome {
    padding: 30px 0;
  }
  .serviceMain h1 {
    font-size: 22px;
    text-transform: none;
  }
}
