/* HeaderBanner.module.css */
.bannerContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center; /* Center the image if it doesn't fill the entire container */
  align-items: center; /* Center the image vertically if necessary */
  overflow: hidden; /* Hide any overflow to keep things tidy */
}

.bannerContainer img {
  width: 100%;
  height: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the full image is shown without cropping */
}
