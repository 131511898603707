.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 450px;
  margin: 0 10px;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 17px;
  cursor: pointer;
  color: #333;
}

.logo {
  width: 120px;
}

.welcomeText {
  margin: 10px 0 25px 0;
}
.welcomeText h3 {
  font-size: 1.25rem;
  color: #09090b;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: -0.025em;
  text-align: center;
}
.welcomeText p {
  color: hsl(240 3.8% 46.1%);
  font-size: 0.875rem;
  margin-top: 6px;
  line-height: 1.25rem;
  text-align: center;
}

.inputGroup {
  margin-bottom: 15px;
  text-align: left;
  position: relative;
}

.inputGroup label {
  display: block;
  margin-bottom: 10px;
  font-size: 0.875rem;
  color: rgb(100, 100, 100);
  font-weight: bold;
}

.inputGroup label span {
  color: red;
}

.inputGroup input {
  width: 100%;
  font-size: 17px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.inputGroup input::placeholder {
  color: #8f8f8f;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.noScroll {
  overflow: hidden;
}

.loginButton {
  width: 100%;
  padding: 12px 14px;
  font-size: 14px;
  background-color: #006064;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #004d4d;
}
.googleButton {
  width: 100%;
  padding: 12px 14px;
  font-size: 14px;
  background-color: #fff;
  color: black;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  transition: 0.2s;
}
.googleButton img {
  width: 15px;
}
.googleButton:hover {
  background-color: #eaeaea;
  transition: 0.2s;
}

.continueWit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #ccc;
  margin: 0 10px;
}

.continueWit p {
  font-size: 12px;
  color: #7a7a7a;
  text-transform: uppercase;
}
.registerbtn {
  margin: 15px 0;
  font-size: 12px;
  color: #7a7a7a;
}

.registerbtn span {
  cursor: pointer;
  color: #004d4d;
}

.loader {
  width: 40px;
}

/* otp modal  */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 450px;
  position: relative;
}

.title {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.reenterEmail {
  font-size: 14px;
  color: #0c6fb5;
  font-weight: bold;
  text-decoration: underline;
}

.otpInputs {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 25px 0;
}

.otpInput {
  width: 50px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otpInput:focus {
  border-color: #0095ff;
  outline: none;
}

.verifyButton {
  background-color: #1c5560;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: ease-in-out;
}

.verifyButton:hover {
  transition: ease-in-out;
  background-color: #1d6776;
}
