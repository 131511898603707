.policyContainer {
  /* max-width: 800px; */
  margin: auto;
  padding: 20px 60px;
  font-family: Arial, sans-serif;
  color: #333;
}

.topPolicyTitle {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.policySection {
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid rgb(207 207 207);
  padding: 20px;
}

.policyTitle {
  color: #1c5560;
  font-size: 22px;
  margin-bottom: 10px;
}

.policySubTitle {
  font-weight: 600;
  color: #5b5b5b;
  margin-top: 15px;
  margin-bottom: 5px;
}

.policySection p,
.policySection ul {
  font-size: 16px;
  line-height: 1.6;
  color: #4f4f4f;
}

.policySection ul {
  padding-left: 20px;
}

.policySection a {
  color: #1c5560;
  text-decoration: none;
  font-weight: bold;
}

.policySection a:hover {
  text-decoration: underline;
}
.bottomText p {
  text-align: center;
}
.bottomText p a {
  color: #1c5560;
  text-decoration: none;
  font-weight: bold;
}
.returnHome p {
  color: #1c5560;
  text-decoration: none;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
}
.returnHome p:hover{
  text-decoration: underline;
}
@media screen and (max-width: 800px) {
  .policyContainer {
    padding: 20px 30px;
  }
  .topPolicyTitle {
    font-size: 25px;
  }
  .policyTitle {
    font-size: 18px;
  }
  .policySubTitle {
    margin-top: 10px;
    font-size: 16px;
  }
  .policySection p,
  .policySection ul {
    font-size: 14px;
    line-height: 1.4;
  }
}
