.newsletterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 60px;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: 0 auto;
}

.newsletterContent {
  max-width: 60%;
}

.newsletterTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.newsletterDescription {
  color: #555;
  margin-bottom: 0;
}

.newsletterForm {
  display: flex;
  align-items: center;
  gap: 10px;
}

.newsletterInput {
  padding: 13px; 
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
  font-size: 15px;
  min-width: 300px;
}
.newsletterInput::placeholder {
  font-size: 14px;
  color: #656565;
}
.message{
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.newsletterButton {
  padding: 13px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.newsletterButton:hover {
  background-color: #333;
}
.newsletterButtonLoader{
  padding: 10px 17px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.newsletterButtonLoader img{
  width: 20px;
}
@media screen and (max-width: 1000px) {
  .newsletterContainer {
    padding: 60px 40px;
  }
}
@media screen and (max-width: 930px) {
  .newsletterContainer {
    justify-content: center;
    flex-direction: column;
    padding: 40px 0px;
  }
  .newsletterContent {
    max-width: 80%;
  }
  .newsletterTitle {
    text-align: center;
  }

  .newsletterDescription {
    text-align: center;
  }
}
@media screen and (max-width: 550px) {
  .newsletterContainer {
    justify-content: center;
    flex-direction: column;
    padding: 40px 0px;
  }
  .newsletterContent {
    max-width: 90%;
  }

  .newsletterInput {
    padding: 10px;
    font-size: 17px;
    min-width: 140px;
  }
}
@media screen and (max-width: 400px) {

  .newsletterContent {
    max-width: 90%;
  }

  .newsletterInput {
    padding: 10px;
    font-size: 16px;
    min-width: 100px;
  }
  .newsletterDescription {
    font-size: 12px;
  }
  
  .newsletterButton {
    padding: 11.5px 14px;
  }
}
