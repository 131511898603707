.navbarItem {
  display: flex;
  align-items: center;
  padding: 15px 60px;
  justify-content: space-between;
}

.stickyNavCome {
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: 100%;
  background-color: white;
  z-index: 10;
}

.noScroll {
  overflow: hidden;
}

/*  RIGHT SIDE ICONS   */
.navItems ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navItems ul li {
  list-style: none;
  padding: 0 10px;
  position: relative;
}

.navItemSearchch a.active::after,
.navItemSearchch a:hover::after {
  content: "Search";
  position: absolute;
  bottom: -24px;
  color: white;
  left: 20px;
  line-height: 24px;
  height: 25px;
  width: 48px;
  font-size: 13px;
  text-align: center;
  background-color: rgb(71, 71, 71);
}

.navItemUser a.active::after,
.navItemUser a:hover::after {
  content: "User";
  position: absolute;
  bottom: -21px;
  color: white;
  left: 20px;
  line-height: 24px;
  height: 25px;
  width: 40px;
  font-size: 13px;
  text-align: center;
  background-color: rgb(71, 71, 71);
}

.navItemWhitlist a.active::after,
.navItemWhitlist a:hover::after {
  content: "whitlist";
  position: absolute;
  bottom: -25px;
  color: white;
  left: 20px;
  line-height: 25px;
  height: 25px;
  width: 50px;
  font-size: 13px;
  text-align: center;
  background-color: rgb(71, 71, 71);
}

.navItemCart a.active::after,
.navItemCart a:hover::after {
  content: "Cart";
  position: absolute;
  bottom: -21px;
  color: white;
  left: 20px;
  height: 25px;
  width: 38px;
  line-height: 25px;
  font-size: 13px;
  text-align: center;
  background-color: rgb(71, 71, 71);
}

.navItems ul li a {
  font-size: 20px;
  color: black;
}

.navItems ul li a:hover {
  color: rgb(99, 99, 99);
}

.navItemCart {
  position: relative;
}

.navItemCart span {
  position: absolute;
  top: -12px;
  right: -5px;
  background-color: black;
  color: #fff;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: bold;
}
.navItemWhitlist span {
  position: absolute;
  top: -12px;
  right: -5px;
  background-color: black;
  color: #fff;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: bold;
}

/* MIDDLE BRAND NAME  */
.brandName a {
  margin-left: 80px;
}

.brandName a img {
  width: 120px;
}

/* SIDE NAVBAR LIST */
.navbarMenuIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.navbarMenuIcon i {
  font-size: 20px;
  color: black;
  cursor: pointer;
}

.navbarMenuIcon i:hover {
  color: rgb(99, 99, 99);
}

.closedIcon {
  position: absolute;
  background-color: #ffffff;
  right: -50px; /* Adjust this value to position the icon outside the sidebar */
  top: 20px;
  z-index: 1000;
  padding: 10px;
  cursor: pointer;
}

.categoryList {
  display: block;
  width: 400px;
}

.categoryList div {
  cursor: pointer;
}

.navItemCurrency select {
  padding: 5px;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  font-size: 12px;
}

.navItemCurrency select option {
  background-color: #fff;
}

.currencyNavbarlist {
  display: none;
}

@media screen and (max-width: 800px) {
  .navItemWhitlist {
    display: none;
  }

  /* remove currency from front  */
  .navItems ul .navItemCurrency {
    display: none;
  }

  .currencyNavbarlist {
    display: block;
  }

  .navItemCurrency select {
    padding: 10px;
    font-size: 14px;
  }

  .navbarItem {
    padding: 15px 30px;
  }

  .brandName a {
    margin-left: 50px;
  }
}

@media screen and (max-width: 650px) {
  .navbarItem {
    padding: 10px 30px;
  }

  .navbarMenuIcon i {
    font-size: 21px;
  }

  .categoryList {
    width: 300px;
  }
}

@media screen and (max-width: 400px) {
  .brandName a {
    margin-left: 30px;
  }

  .brandName a img {
    width: 80px;
  }

  .categoryList {
    width: 250px;
  }
}
