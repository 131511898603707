.cartMainSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 0;
  margin: 0 20px;
}

/* CART SECTION  */
.cartSection {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  width: 100%;
  max-width: 900px;
}

.cartHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.cartHeading h2 {
  text-align: center;
  padding-bottom: 40px;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
}

.cartSection table {
  border-collapse: collapse;
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.cartSection table thead tr th {
  text-align: center;
  background-color: #1c5560;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 0;
}

.cartSection table tbody tr td {
  padding: 8px;
  text-align: center;
}

.cartImagesDetail {
  display: flex;
  width: 100%;
}

.cartImagesDetail img {
  width: 50px;
  height: 70px;
  margin-right: 5px;
}

.cartDetail {
  margin-left: 5px;
  line-height: 1.5;
  text-align: start;
}

.cartDetail p:nth-child(2) {
  font-size: 13px;
  color: #585858;
}

.cartDetail p:nth-child(3):hover {
  font-size: 17px;
  transition: 0.3s;
}

.cartContent {
  margin: 20px;
}

.cartContent:hover {
  background-color: #e0e0e0;
}

/* ORDER SUMMARY  */
.cartSummary {
  width: 100%;
  max-width: 300px;
  margin-left: 45px;
}

.cartSummary h2 {
  /* text-align: center; */
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
}

.cartSummary p {
  margin-bottom: 20px;
  border: 1px solid rgb(199, 199, 199);
}

.highlight h3 {
  color: #5bb493 !important;
}
.promoCode{
  width: 100%;
}
.promoCode form {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.promoCode  input {
  width: 100%;
  height: 31px;
  padding-left: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: flex;
  font-weight: bold;
}

.promoCode  input::placeholder {
  color: #888;
  font-size: 12px;
}

.promoCode div button {
  background-color: #1c5560;
  color: white;
  border-radius: 3px;
  height: 30px;
  width: 70px;
  font-size: 12px;
  text-transform: none;
  border: none;
  outline: none;
}
.promoCode div button img {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.cartAmount {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  text-align: left;
}

.cartAmountRupee {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cartAmount h3 {
  text-align: center;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  text-transform: none;
}

.cartAmount h3:nth-child(2) {
  color: #484848;
  font-size: 15px;
}

.cartAmountButtons {
}

.cartAmountButtons button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  background-color: #1c5560;
  width: 100%;
  text-transform: none;
  color: white;
  font-weight: bold;
  padding: 10px 10px;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
}

/* CART DATA NOT FOUND  */
.cartDataNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.cartDataNotFound div h3 {
  font-size: 25px;
  text-transform: none;
}

.cartDataNotFound div p {
  font-size: 15px;
  margin: 15px 0;
  color: rgb(99, 99, 99);
  text-transform: none;
}

.cartDataNotFound div button {
  font-size: 14px;
  margin: 10px 0;
  padding: 9px 20px;
  background-color: #1c5560;
  color: white;
  border-radius: 17px;
  border: none;
  text-transform: none;
}
.freeDeliveryTag {
  color: #8bc7b7;
  font-weight: bold;
  margin-bottom: 13px;
  font-size: 13px;
}

.cartLoadingMainSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 20px 70px;
}

.cartLoadingSection {
  display: flex;
  gap: 15px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
}
.cartLoadingSection * {
  margin: 5px 0;
}

@media screen and (max-width: 1300px) {
  .cartSection {
    width: 100%;
    max-width: 700px;
  }
}

@media screen and (max-width: 1100px) {
  .cartSection {
    width: 100%;
    max-width: 500px;
  }
}

@media screen and (max-width: 900px) {
  .cartSection {
    width: 100%;
    max-width: 800px;
  }

  .cartSummary {
    margin: 30px 0;
    max-width: 100%;
  }

  .cartSummary h2 {
    padding-top: 15px;
    font-weight: bold;
  }
}

@media screen and (max-width: 700px) {
  .cartImagesDetail {
    align-items: flex-start;
    flex-direction: column;
  }

  .cartDetail {
    margin: 5px 0;
  }

  .cartDetail p:nth-child(1) {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .cartLoadingMainSection {
    gap: 2rem;
    padding: 20px 30px;
  }
}
@media screen and (max-width: 480px) {
  .cartSection table thead tr th {
    font-size: 12px;
  }

  .cartSection table tbody tr td {
    font-size: 13px;
  }

  .cartDataNotFound div img {
    width: 300px;
  }
}
