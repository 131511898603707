/* Modal styles */
.product-detail-modal {
    display: none;
    position: fixed;
    z-index: 1;
    margin: auto;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
}

.product-detail-modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px 15px;
    border: 1px solid #888;
    max-width: 900px;
}

.product-detail-modal-content span {
    color: #646464;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.product-detail-modal-content span:hover,
.product-detail-modal-content span:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

body.modal-open {
    overflow: hidden;
}

/* {MAIN SECTION } */
.modal-product-details-container {
    display: flex;
    /* border: 2px solid blue; */
    justify-content: center;
    flex-wrap: wrap;
}

/* LEFT SECTION OF IMG  */
.modal-product-image-container img {
    width: 350px;
    height: 490px;
    object-fit: cover;
}

.modal-product-image-container div button {
    /* padding: 20px; */
    width: 350px;
    height: 25px;
    background-color: black;
    color: white;
    font-size: 12px;

}
.modal-product-image-container div button  a{
    color: white;
}
.modal-product-image-container div button:hover{
    background-color: rgb(41, 41, 41);
}


/* PRODUCT DETAIL RIGHT SECTION  */
.modal-product-details {
    width: 450px;
    /* border: 2px solid red; */
    padding-left: 30px;
    line-height: 1.5;
}

.modal-product-details h2 {
    margin: 10px 0;
    text-transform: none;
    font-size: 20px;
    color: #333;
}

.modal-product-details p:nth-child(1) {

    color: green;
    font-size: 15px;
}

.modal-product-details p:nth-child(3) {

    font-weight: 400;
    font-size: 17px
}


/* {Quantity varies} */

.modal-quantity-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.modal-quantity-control {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.modal-quantity-control button {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.modal-quantity-control button:hover {
    background-color: #e0e0e0;
}

.modal-quantity-control input {
    width: 40px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin: 0 5px;
}


/* {wishlist} */
.modal-add-icons-detaiils i {
    font-size: 15px;
}

/* {add to cart button } */
.modal-add-to-cart-button button {
    padding: 10px 20px;
    background-color: #3eb049;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin: 18px 0;
    margin-inline-end: 10px;
}

.modal-add-to-cart-button button:hover {
    background-color: #2c9140;
}


/* {more color available} */
.modal-product-image-container-more-colors {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.modal-product-image-container-more-colors div img {
    width: 60px;
    margin-right: 10px;
    border: 1px solid rgb(162, 161, 161);
    cursor: pointer;
}



.modal-product-image-container-more-colors div img.active {
    border: 2px solid black;
}




@media screen and (max-width:1163px) {
    .product-image-containerr-top-show-img {
        width: 450px;
    }

}

@media screen and (max-width:1063px) {
    .product-detailss {
        padding-left: 35px;
    }

    .product-image-containerr-top-show-img {
        width: 400px;
    }

}


@media screen and (max-width:1014px) {
    .product-detailss {
        padding: 20px 0px;
        width: 550px;
    }

    .product-image-containerr-top-show-img {
        width: 550px;
    }

    /* .product-details-containerr */
}

@media screen and (max-width:680px) {
    .product-detailss {
        padding: 20px 0px;
        width: 550px;
    }

    .product-image-containerr {
        flex-direction: column-reverse;
    }

    .product-image-containerr-top-show-img {
        width: 550px;
    }

    .product-image-containerr-more-colors {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        /* justify-content: space-around ; */
        flex-wrap: wrap;
        /* border: 2px solid red; */

    }

    .product-image-containerr-more-colors div img {
        width: 50px;
        border-radius: 50%;
        height: 50px;

    }

    .product-details-containerr {
        margin: 0 20px;
        padding: 30px 0;

    }
}

@media screen and (max-width:570px) {
    .product-image-containerr-top-show-img {
        width: 500px;
        height: 719.08px;
    }

    .product-details-top-container-navigation p {
        font-size: 14px;

    }

    .product-detailss h3 {
        font-size: 18px;
    }

    .product-detailss h2 {
        font-size: 22px;
    }

    .product-details-top-container-navigation {
        margin: 10px 0;
    }

    .single-product-options {
        margin-bottom: 15px;
    }

    .single-product-options p {
        font-size: 15px;
    }

}

@media screen and (max-width:520px) {
    .product-image-containerr-top-show-img {
        width: 450px;
        height: 647.65px;
    }
}

@media screen and (max-width:480px) {
    .product-image-containerr-top-show-img {
        width: 350px;
        height: 504.8px;
    }

    .product-image-containerr-more-colors div img {
        width: 40px;

    }
}

@media screen and (max-width:410px) {
    .single-product-options p {
        font-size: 13px;
    }


}