.navtopbanner {
  background-color: #1c5560;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 8px 60px;
}

.navtopbanner p {
  font-weight: bold;
  font-size: 13px;
}

.navtopbanner2 p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-right: 35px;
}

.navtopbanner3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.navtopbanner3 a {
  color: white;
}

.navtopbanner3 a:visited {
  color: white;
}

.navtopbanner3 a:hover {
  color: rgb(186, 186, 186);
}

@media screen and (max-width: 1150px) {
  .navtopbanner {
    display: block;
  }

  .navtopbanner1 {
    display: none;
  }

  .navtopbanner3 {
    display: none;
  }

  .navtopbanner2 {
    margin-left: 20px;
  }
  .navtopbanner2 p {
    margin-right: 0px;
  }
}
