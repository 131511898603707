.OrderDetails {
  padding: 50px 60px;
}

/* top bar for order and buttons  */
.orderDetailTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* order id and placed and arrow  */
.orderDetailsTopFirst {
  display: flex;
  gap: 12px;
}

/* arrow icon  */
.orderDetailsTopFirst div:nth-child(1) {
  font-size: 20px;
  color: black;
}
.orderDetailsTopFirstLeft a {
  font-size: 25px;
}
.orderDetailsTopFirstLeft a:active {
  color: black;
}

/* order id  */
.orderDetailsTopFirst div:nth-child(2) div:nth-child(1) {
  color: black;
  font-weight: bold;
}

/* placed date  */
.orderDetailsTopFirst div:nth-child(2) div:nth-child(2) {
  margin-top: 2px;
  font-size: 14px;
  color: #6a6565;
}
/* Dispatch notice  */
.cancelOrderTooltip {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  line-height: 20px;
  color: #ef4441;
}
.cancelOrderTooltip img {
  width: 15px;
  margin-top: 3px;
}

.orderDetailsTopSecond {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
}

.orderDetailsTopSecond .getInvoice {
  font-size: 14px;
  text-transform: none;
  letter-spacing: 0.2px;
  border-radius: 4px;
  background-color: white;
  border: 0.3px solid gray;
  height: 40px;
  width: 100px;
}

.orderDetailsTopSecond .threeDotsButton {
  text-transform: none;
  letter-spacing: 0.2px;
  border-radius: 4px;
  background-color: white;
  border: 0.3px solid gray;
  height: 40px;
  color: black;
  width: 30px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.orderDetailsTopSecond .threeDotsButton:hover {
  background-color: rgb(230, 230, 230);
}
.dropdownMenu {
  position: absolute;
  right: 0;
  top: 100%;
  width: 160px;
  margin-top: 8px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
  border: 0.3px solid rgb(213, 213, 213);
  z-index: 10;
}

.dropdownItem {
  padding: 9px 13px;
  width: 100%;
  text-align: left;
  font-size: 13px;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  transition: background 0.2s;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
}

/* order summary and details  */
.orderDetailsSecond {
  margin: 30px 0;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  gap: 30px;
}

.orderDetailsSecondFirstFirst {
  margin-bottom: 20px;
}

/* order detail section  */
.orderDetailSection {
  border: 1px solid rgb(210, 210, 210);
  border-radius: 5px;
  width: 100%;
  padding: 20px;
}

.orderDetailsSecondFirst {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.orderDetailsMyorder {
}

/* order detail heading  */
.orderDetailSection h3 {
  text-transform: none;
  font-size: 21px;
}

.orderDetailsContact {
  margin: 15px 0;
}

/* heading like name contact info  */
.orderDetailsContact p {
  line-height: 25px;
  color: rgb(56, 56, 56);
  font-size: 15px;
}

.orderDetailsContact p:nth-child(1) {
  font-size: 17px;
  color: rgb(0, 0, 0);
  margin: 3px 0;
  font-weight: 600;
}

/* order summary section   */
.orderDetailsSecondSecond {
  padding: 20px 30px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 5px;
  width: 100%;
}

/* order summry heading  */
.orderDetailsSecondSecond h3 {
  text-transform: none;
  font-size: 21px;
}

.checkoutCartSection {
  padding: 20px;
}

.checkoutCartSection table {
  border-collapse: collapse;
  width: 100%;
}

.checkoutCartSection table tbody tr td {
  text-align: right;
  font-size: 14px;
  padding: 10px 0;
}

.checkoutCartImagesDetail {
  display: flex;
  gap: 8px;
  width: 100%;
  position: relative;
}

.checkoutCartImagesDetail img {
  width: 50px;
  height: 70px;
  border: 1px solid rgb(195, 195, 195);
}

.checkoutCartImagesDetail span {
  position: absolute;
  top: -10px;
  left: 40px;
  border-radius: 50%;
  background-color: rgb(90, 90, 90);
  color: white;
  padding: 3px 6px;
  font-size: 10px;
}

.checkoutCartDetail {
  line-height: 1.5;
  text-align: start;
}

.checkoutCartDetail p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
}

.checkoutCartDetail p:nth-child(2),
.checkoutCartDetail p:nth-child(3) {
  font-size: 12px;
  color: #363636;
}

.checkoutCartSectionPaymentDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkoutCartSectionPaymentDetail div p {
  font-size: 14px;
  color: black;
  margin: 14px 0;
}

.payTotal {
  color: black;
  font-weight: bold;
  font-size: 16px !important;
}

/* order cancel extra section  */
.orderTopCancelSection {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 25px;
  border-radius: 5px;
  border: 1px solid #ef4441;
  margin-top: 28px;
}
.orderTopCancelSection div img {
  width: 1rem;
}
.orderTopCancelSectionText {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}
.orderTopCancelSectionText h5 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.025em;
  line-height: 1;
  color: #ef4441;
}
.orderTopCancelSectionText p {
  color: #ef4441;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
/* refund status  */
.orderTopRefundSection {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 25px;
  border-radius: 5px;
  border: 1px solid rgb(210, 210, 210);
  margin-top: 28px;
}
.orderTopRefundSection div img {
  width: 1rem;
}
.orderTopRefundSectionText {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}
.orderTopRefundSectionText h5 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.025em;
  line-height: 1;
  color: #1d1d1d;
}
.orderTopRefundSectionText p {
  color: #454545;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.orderDeliveredReviewButton button {
  background-color: black;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25rem;
  padding: 8px 10px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  outline: none;
}
/* prgress tracker  */

/* ProgressTracker.css */
.orderProgress {
  height: 27vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 5px;
}

.progressTracker {
  width: 100%;
  padding: 25px;
  font-family: Arial, sans-serif;
}

.progressBarEstimate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.estimateDelivery {
  color: #24b770;
  margin: 10px 0;
}
.steps {
  display: flex;
  justify-content: flex-start;

  gap: 25px;
  margin-bottom: 15px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(125, 125, 125);
}

.step.active {
  color: black;
}

.step .icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.label {
  font-size: 14px;
  text-align: center;
}

.progressBar {
  position: relative;
  width: 100%;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 2.5px;
}

.progress {
  height: 100%;
  background-color: black;
  border-radius: 2.5px;
  transition: width 0.3s ease;
}
.cancelledProgress {
  background-color: #ef4441;
}
.info {
  margin-top: 10px;
  font-size: 12px;
  color: #666;
}

.link {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.cartAmount {
  padding: 20px 0;
}

.highlight h3 {
  color: #5bb493 !important;
}

.cartAmountRupee {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cartAmountRupee h3 {
  text-align: center;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  text-transform: none;
}
@media screen and (max-width: 1200px) {
  .orderDetailsSecond {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 800px) {
  .OrderDetails {
    padding: 50px 30px;
  }
}

@media screen and (max-width: 700px) {
  .orderDetailsTopFirst {
    gap: 6px;
  }

  .orderDetailsTopFirst div:nth-child(1) {
    font-size: 15px;
  }

  .orderDetailsTopFirst div:nth-child(2) div:nth-child(2) {
    font-size: 12px;
  }

  /* .orderDetailsTopSecond div button {
    font-size: 10px;
    letter-spacing: 0px;
    padding: 7px 6px;
  } */

  .orderDetailsSecond {
    gap: 20px;
  }

  .orderDetailsTopSecond .getInvoice {
    font-size: 13px;
    height: 35px;
    width: 90px;
  }
  .orderDetailsTopSecond .threeDotsButton {
    height: 35px;
    font-size: 15px;
  }
  /* progress  */

  .orderProgress {
    height: 24vh;
  }

  .steps {
    gap: 15px;
    margin-bottom: 10px;
  }

  .step .icon {
    font-size: 17px;
  }

  .label {
    font-size: 11px;
  }

  /* order summarry */
  .orderDetailsSecondSecond {
    padding: 15px 0 0px 0px;
    width: 690px;
  }

  .orderDetailsSecondSecond h3 {
    text-align: center;
    font-size: 18px;
  }

  /* order details  */
  .orderDetailSection {
    width: 690px;
    padding: 15px 10px;
  }

  .orderDetailSection h3 {
    text-align: center;
    font-size: 18px;
  }
  .cartAmountRupee h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .orderDetailsSecondFirst {
    justify-content: flex-start;
  }
  .estimateDelivery {
    color: #24b770;
    font-size: 12px;
    margin: 10px 0;
  }

  .orderDetailsContact p {
    /* text-align: center; */
    font-size: 13px;
    line-height: 22px;
  }

  .orderDetailsContact p:nth-child(1) {
    font-size: 15px;
    margin: 3px 0;
  }
  .checkoutCartDetail p:nth-child(1) {
    font-size: 14px;
  }
  .orderTopCancelSection {
    padding: 15px;
  }
  .orderTopRefundSection {
    padding: 15px;
  }
  .orderTopRefundSectionText h5 {
    font-size: 14px;
  }
  .orderTopRefundSectionText p {
    font-size: 0.8rem;
  }
  .orderTopCancelSectionText h5 {
    font-size: 14px;
  }
  .orderTopCancelSectionText p {
    font-size: 0.8rem;
  }
}
