/* SIDE NAVBAR LIST */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Ensure it is below the sidebar */
  display: none;
}
.overlay.open {
  display: block;
}
.sidebar {
  position: fixed;
  top: 0;
  left: -350px;
  width: 350px;
  height: 100%;
  background-color: white;
  transition: left 0.3s ease;
  z-index: 1001;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.sidebar.open {
  left: 0;
}
.crossIcon {
  position: absolute;
  top: 0;
  left: 350px;
  background-color: #222222;
  padding: 10px;
  cursor: pointer;
  display: none;
}
.crossIcon.open {
  display: block;
}
.crossIcon img {
  width: 20px;
  height: 20px;
}

.navsideBarMenuList {
  padding: 20px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  border-bottom: 1px solid rgb(202, 202, 202);
  width: 100%;
  color: #222;
  transition: 0.3s ease-in-out;
}

.nasidebarvsideBarMenuList i {
  cursor: pointer;
}

.navsideBarMenuList p:nth-child(2) a,
.navsideBarMenuList p:nth-child(3) a {
  display: flex;
  align-items: center;
  color: #878787;
  gap: 5px;
  text-decoration: underline;
  padding-bottom: 1px;
  cursor: pointer;
}

.btnListNavbar {
  padding: 0 !important;
  margin: 0;
  gap: 0;
}

.btnListNavbar button {
  width: 50%;
  outline: none;
  border: none;
  transition: 0.2s;
  color: #222;
  background-color: #f6f6f6;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  height: 55px;
  cursor: pointer;
}

.btnListNavbar button:hover {
  background-color: rgb(225, 225, 225);
  transition: 0.2s;
  color: black;
}

.btnListNavbar button.activebtn {
  background-color: #46464626;
  border-bottom: 2px solid #1c5560;
}

.navsideBarMenuList ul {
  text-decoration: none;
  list-style: none;
}

.navItemCurrency select {
  padding: 5px;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  font-size: 12px;
}

.navItemCurrency select option {
  background-color: #fff;
}

.currencyNavbarlist {
  display: none;
}
